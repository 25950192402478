<template>
	<div>
		<div class="md:mb-0 pb-6 md:pb-0" :class="{ 'mb-24': isLoggedIn }">
			<div v-if="isMobile" class="relative">
				<Suspense>
					<slot />
				</Suspense>
				<!-- <Suspense>
          <slot></slot>
        </Suspense> -->
			</div>
			<div
				v-if="!isMobile"
				class="max-w-[592px] mx-auto border-2 border-gray-900 rounded-xl mt-6 relative desktop-container"
			>
				<div
					id="mockupWindow"
					class="mobile-mockup-scroll overflow-y-auto"
					:class="{ authenticated: isLoggedIn }"
				>
					<div class="max-w-[414px] mx-auto pb-6" :class="{ 'mb-24': isLoggedIn }">
						<!-- <Suspense>
              <NuxtPage />
            </Suspense> -->
						<Suspense>
							<slot></slot>
						</Suspense>
					</div>
				</div>
				<DefaultFooterTabs class="justify-evenly absolute bottom-0" v-if="isLoggedIn" />
			</div>
			<DefaultFooterTabs class="fixed bottom-0 md:hidden" v-if="isLoggedIn" />
			<div :class="{ 'page-container': isMobile }">
				<DefaultFloatingButton class="z-50">
					<div class="flex flex-col gap-4" id="toast-container" />
				</DefaultFloatingButton>
			</div>
		</div>
		<DefaultModal
			v-if="needAppUpdate"
			:displayModal="needAppUpdate"
			:title="$t('index.modalUpdateApp.title')"
			hideClose
			:canClose="false"
		>
			<h2 class="text-white text-xlg mb-6 text-center">
				{{ $t('index.modalUpdateApp.description') }}
			</h2>
			<DefaultButton @click="goToPlayStore">{{ $t('index.modalUpdateApp.updateButton') }}</DefaultButton>
		</DefaultModal>
		<ToolsSubscribeOfferModal />
		<AccountSettingsFeedbackModal />
	</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { isMobile } = useDevice()
const { locale } = useI18n()

const { user: loggedUser, isLoggedIn } = useUserStore()

const needAppUpdate = ref(false)
const location = ref('')

onMounted(async () => {
	if (!loggedUser) {
		const browserLocale = await $getLocale()
		location.value = browserLocale?.split('-')[1]
		locale.value = browserLocale
	} else {
		location.value = loggedUser.location
		locale.value = loggedUser.lang
		$identifyUser(loggedUser)
	}

	await checkAppVersion()
	const nuxtApp: any = useNuxtApp()
	nuxtApp.$addPushNotificationsListener()
})

async function updateUserLocation() {
	try {
		if (loggedUser) {
			await $api('/users/location', {
				method: 'PUT',
				body: { location: location.value },
			})
		}
	} catch (error) {
		// nuxtApp.$sentry.captureException(error);
		$sentryCaptureException(error)
	}
}

async function checkAppVersion() {
	const { Capacitor } = await import('@capacitor/core')
	if (Capacitor.getPlatform() === 'web') {
		return
	}

	const { App } = await import('@capacitor/app')
	const { version: appVersion } = await App.getInfo()

	const { version: serverVersion }: any = await $fetch('/android-app-version.json')
	const intAppVersion = parseInt(appVersion.replace(/\./g, ''))
	const intServerVersion = parseInt(serverVersion.replace(/\./g, ''))
	if (intServerVersion > intAppVersion) {
		needAppUpdate.value = true
	}
}

function goToPlayStore() {
	window.open('https://play.google.com/store/apps/details?id=net.realityavatar.app&pcampaignid=web_share')
}

watch(
	() => isLoggedIn,
	newValue => {
		if (newValue) {
			locale.value = loggedUser?.lang || 'en-US'
			// identifyUser(loggedUser);
		}

		if (loggedUser && loggedUser.location !== location.value) {
			updateUserLocation()
			loggedUser.location = location.value
		}
	}
)

// watch(
//   () => loggedUser?.value.lang,
//   (newValue) => {
//     nuxtApp.$i18n.locale = newValue;
//   }
// );
</script>

<style scoped>
.desktop-container {
	height: calc(100vh - 48px);
	max-height: 950px;
}

.mobile-mockup-scroll.authenticated {
	height: calc(100% - 96px);
}

.mobile-mockup-scroll {
	height: calc(100%) !important;
}
</style>
